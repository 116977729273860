<template>
  <div class="relative">
    <!-- Background with opacity -->
    <div class="absolute inset-0 bg-white opacity-70"></div>
    <!-- Content -->
    <div class="relative z-10 py-8 px-6 flex flex-col gap-6 tracking-wide text-sm sm:text-base font-light">
      <!-- Title Section -->
      <div v-if="title">
        <div v-if="title" class="flex flex-col gap-1">
          <h3 class="text-4xl sm:text-5xl lg:text-6xl font-bold tracking-wide font-heading">
            {{ title }}
          </h3>
          <div v-if="subtitle" class="text-base sm:text-lg font-semibold tracking-wide">
            {{ subtitle }}
          </div>
          <div v-if="subsubtitle" class="text-sm font-light sm:text-base tracking-widest">
            {{ subsubtitle }}
          </div>
        </div>
      </div>
      <slot>
        <div v-if="content" class="text-sm font-light ">
          {{ content }}
        </div>
      </slot>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      required: false
    },
    subtitle: {
      type: String,
      required: false
    },
    subsubtitle: {
      type: String,
      required: false
    },
    content: {
      type: String,
      required: false
    },
    dividers: {
      type: Boolean,
      required: false,
      default: false
    },
  }
};
</script>
