
import { createApp } from 'vue/dist/vue.esm-bundler.js'
import Home from '../home.vue'
import Header from '../views/header.vue'
import Card from '../components/card.vue'
import '@fortawesome/fontawesome-free/js/all'

document.addEventListener('DOMContentLoaded', () => {
  const app = createApp({})
  app
    .component("home", Home)
    .component("header-component", Header)
    .component("card", Card)
    .mount('#vue-app')
})
