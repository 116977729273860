<template>
  <div class="relative text-lg sm:text-xl md:text-2xl lg:text-4xl font-semibold font-heading text-deep-blue tracking-wide overflow-visible">
    <img :src="currentBackgroundImage" alt="" style="aspect-ratio: 5315/12402" class="w-full transition-none" />
    <a v-if="showEvents" class="absolute" :style="{ top: `${eventsTop}%`, left: '70%'}" href="/events">events</a>
    <a v-if="showTravel" class="absolute" :style="{ top: `${travelTop}%`, left: '14%'}" href="/travel">travel</a>
    <a v-if="showWhereToStay" class="absolute" :style="{ top: `${whereToStayTop}%`, left: '63%'}" href="/where_to_stay">where to stay</a>
    <div class="absolute inset-x-0 bottom-1/5 uppercase text-center text-orange font-heading" style="font-size: 12vw; bottom: 20%">
      Pippa & Ollie
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, computed } from 'vue'

const currentBackgroundImage = ref('/background_P&O.png')
const width = ref(window.innerWidth)
const height = ref(window.innerHeight)


const showEvents = ref(false)
const showTravel = ref(false)
const showWhereToStay = ref(false)


const scrollEventAddition = ref(0)
const scrollTravelAddition = ref(0)
const widthEventAddition = ref(0)
const widthTravelAddition = ref(0)
const widthWhereToStayAddition = ref(0)

const eventsTop = computed(() => {
  return 42 - scrollEventAddition.value + widthEventAddition.value
});
const travelTop = computed(() => {
  return 45.5 - scrollTravelAddition.value + widthTravelAddition.value
})
const whereToStayTop = computed(() => {
  return 48 + widthWhereToStayAddition.value
})

const scrollThresholdOne = ref(40)
const scrollThresholdTwo = ref(80)
const scrollThresholdThree = ref(120)

const handleScroll = () => {
  const scrollPosition = window.scrollY
  if (scrollPosition > scrollThresholdThree.value) {
    currentBackgroundImage.value = '/background_P&O_extended_3.png'
    showEvents.value = true
    showTravel.value = true
    showWhereToStay.value = true
    scrollEventAddition.value = 2
    scrollTravelAddition.value = 1
  } else if (scrollPosition > scrollThresholdTwo.value) {
    currentBackgroundImage.value = '/background_P&O_extended_2.png'
    showEvents.value = true
    showTravel.value = true
    showWhereToStay.value = false
    scrollEventAddition.value = 1
    scrollTravelAddition.value = 0
  } else if (scrollPosition > scrollThresholdOne.value) {
    currentBackgroundImage.value = '/background_P&O_extended_1.png'
    showEvents.value = true
    showTravel.value = false
    showWhereToStay.value = false
    scrollEventAddition.value = 0
  } else {
    currentBackgroundImage.value = '/background_P&O.png'
    showEvents.value = false
    showTravel.value = false
    showWhereToStay.value = false
  }
}

onMounted(() => {
  updateSize()
  window.addEventListener('scroll', handleScroll)
  window.addEventListener('resize', updateSize)
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
  window.removeEventListener('resize', updateSize)
})

function updateSize() {
  width.value = window.innerWidth
  height.value = window.innerHeight
  if (width.value > 1200) {
    widthEventAddition.value = 1.5
    widthTravelAddition.value = 1.5
    widthWhereToStayAddition.value = 1.5
    scrollThresholdOne.value = width.value / 1.7
    scrollThresholdTwo.value = width.value / 1.3
    scrollThresholdThree.value = width.value / 1.2
  } else if (width.value > 600) {
    widthEventAddition.value = 1
    widthTravelAddition.value = 1
    widthWhereToStayAddition.value = 1
    scrollThresholdOne.value = width.value / 5
    scrollThresholdTwo.value = width.value / 3
    scrollThresholdThree.value = width.value / 2
  } else if (width.value > 400) {
    widthEventAddition.value = 0.5
    widthTravelAddition.value = 0.5
    widthWhereToStayAddition.value = 0.5
    scrollThresholdTwo.value = 100
    scrollThresholdThree.value = 150
  } else {
    widthEventAddition.value = 0
    widthTravelAddition.value = 0
    widthWhereToStayAddition.value = 0
    scrollThresholdOne.value = 50
    scrollThresholdTwo.value = 80
    scrollThresholdThree.value = 120
  }
}


</script>
